import React from "react";
import {
  Container,
  Title,
  SubTitle,
  MathContainer,
  Result,
  EquasionContainer,
  EquasionItem,
  Text,
} from "./styles";

const TextBox = ({ data, altColor }) => {
  return (
    <Container>
      {data.title && <Title data-aos="fade-up"      className="disableAosMobile" altColor={altColor}>{data.title}</Title>}

      {data.subtitle && (
        <SubTitle data-aos="fade-up"     className="disableAosMobile" altColor={altColor}>{data.subtitle}</SubTitle>
      )}

      {data.math && (
        <MathContainer data-aos="fade-up"      className="disableAosMobile" altColor={altColor}>
          <Result>{data.math.result}</Result>
          <EquasionContainer>
            <EquasionItem>{data.math.upper}</EquasionItem>
            {data.math.lower && <EquasionItem>{data.math.lower}</EquasionItem>}
          </EquasionContainer>
        </MathContainer>
      )}

      {data.text && <Text data-aos="fade-up"      className="disableAosMobile" altColor={altColor}>{data.text}</Text>}
    </Container>
  );
};

export default TextBox;
