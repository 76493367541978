import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 45%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    margin-top: 44px;
  }
`;

export const FormGroup = styled.div`
  font-size: 20px;

  &:not(:first-child) {
    margin-top: 42px;
  }

  &:last-child {
    input {
      background-color: #5e5e5e;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 18px;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 16px;

    &:not(:first-child) {
      margin-top: 32px;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 14px;
  }
`;

export const Label = styled.label`
  font-weight: ${({ theme }) => theme.weights.semibold};
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 12px;
  display: block;
`;

export const FormInput = styled.input`
  width: 100%;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.weights.light};
  font-size: 16px;
  padding: 18px;
  border: 1px solid ${({ theme }) => theme.colors.black};
  &:focus {
    border-color: #215fcc;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 14px;
    padding: 14px;
  }
`;
