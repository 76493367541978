import React from "react";
import { graphql, useStaticQuery } from "gatsby";

export const useCalculatorData = () => {
  const query = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "image/calculator-hero.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  const types = {
    textbox: "textbox",
    form: "form",
    table: "table",
  };

  return {
    metaInfo: {
      title: "Calculator",
      ogDescription:
        "For each milling or turning machine, it's very important to choose the right cutting parameters. A wrong choice results in much faster tool wear and higher costs. How do you choose the right cutting parameters? The parameters we look for most often are n = revolutions - and F = feed.",
    },
    hero: {
      title: "Cutting data calculator",
      subtitle:
        "For each milling or turning machine, it's very important to choose the right cutting parameters. A wrong choice results in much faster tool wear and higher costs. How do you choose the right cutting parameters? The parameters we look for most often are n = revolutions - and F = feed.",
      bg: query.img1,

    },
    sections: {
      rpmCalc: {
        left: {
          type: types.textbox,
          data: {
            title: (
              <>
                Turnover calculator <br /> tools <i>n</i>"
              </>
            ),
            subtitle:
              "The cutting parameter calculator n will allow you to easily calculate the spindle speed, i.e. the number of revolutions per minute. Enter the tool diameter and cutting speed.",
          },
        },
        right: {
          type: types.form,
          data: {
            d: {
              label: "D = Tool diameter [mm]",
              value: "10",
              id: "d",
            },
            vc: {
              label: "Vc = Cutting speed [m/min]",
              value: "100",
              id: "vc",
            },
            n: {
              label: "n = RPM",
              id: "rpm",
            },
          },
        },

        bgText: "calculator",
      },
      rpmCalcExplained: {
        left: {
          background: "light_gray",
          type: types.textbox,
          data: {
            title: <>Tool revolutions n</>,
            subtitle:
              "To calculate tool rotations n we need to know the cutting speed Vc, in other words the number of rotations of the cutting blade, given in meters per minute.",
            math: {
              result: "Vc = ",
              upper: <>d * &pi; * n</>,
              lower: "1000",
            },
            text: (
              <>
                where
                <br />d - diameter of the tool or workpiece, given in mm.
              </>
            ),
          },
        },
        right: {
          type: types.textbox,
          data: {
            subtitle:
              "After the transformation, we get the formula for revolutions n:",
            math: {
              result: "n = ",
              upper: "Vc * 1000",
              lower: <>&pi; * d</>,
            },
            text:
              "Too slow cutting speeds lead to edge build-up and blunting, while too fast results in quicker insert wear, deformation and poor finish.",
          },
        },
      },
      feedCalc: {
        left: {
          type: types.textbox,
          data: {
            title: (
              <>
                Tool feed calculator <i>F</i>
              </>
            ),
            subtitle:
              "The tool feed rate calculator F allows you to calculate the feed rate of one cutting edge. This depends on the number of blades on your tool (Z) and the rate of rotation (n).",
          },
        },
        right: {
          type: types.form,
          data: {
            n: {
              label: "n = Tool RPM",
              value: "1592",
              id: "n",
            },
            z: {
              label: "Z = Number of blades",
              value: "2",
              id: "z",
            },
            fz: {
              label: "fz = Feed per tooth [mm/blade]",
              value: "0.2",
              id: "fz",
            },
            f: {
              label: "f = Feed [mm/min]",
              id: "f",
            },
          },
        },
      },
      feedCalcExplained: {
        background: "dark_gray",

        left: {
          type: types.textbox,
          data: {
            subtitle:
              "By knowing the speed n, you can calculate the feed rate F.",
            math: {
              result: "F = ",
              upper: "Fz * n * Z",
            },
            text: (
              <>
                where: <br />
                f – tool feed, <br />
                fz – feed on one blade, <br />
                Z – number of tool blades, <br />
                n – tool or workpiece revolutions <br />
              </>
            ),
          },
        },
        right: {
          type: types.table,
          data: {
            heading: ["", "HSS Steel", "Cemented carbide VHM"],
            rows: [
              ["[m/min]", "10 ÷ 20", "100 ÷ 120"],
              ["fz [mm/tooth]", "0,2 ÷ 0,4", "0,02 ÷ 0,04"],
            ],
          },
        },
      },
    },

    params: {},
    stats: {},
  };
};

export default useCalculatorData;
