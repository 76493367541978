import React from "react";
import SEO from "components/seo";
import Hero from "components/Hero/Hero";
import FeedCalculator from "./Section/Calculators/FeedCalculator";
import RpmCalculator from "./Section/Calculators/RpmCalculator";
import Section from "./Section/Section";
import TextBox from "./Section/TextBox/TextBox";
import {Table, TableBody, TableCell, TableContainer, TableHeaderCell, TableHeading, TableRow,} from "./styles";
import uuid from "react-uuid";

import {SectionsWrappper} from "../../shared/Wrappers/wrappers";

const CalculatorLayout = ({data}) => {

  return (
    <>
      <SEO
        title={data.metaInfo.title}
        description={data.metaInfo.ogDescription}
      />
      <Hero data={data.hero} color="black">
      </Hero>
      <SectionsWrappper>

        <Section parallaxText={data.sections.rpmCalc.bgText}>
          <TextBox data={data.sections.rpmCalc.left.data}/>
          <RpmCalculator data={data.sections.rpmCalc.right.data}/>
        </Section>
        <Section bgColor="lightGray">
          <TextBox data={data.sections.rpmCalcExplained.left.data}/>
          <TextBox data={data.sections.rpmCalcExplained.right.data}/>
        </Section>
        <Section>
          <TextBox data={data.sections.feedCalc.left.data}/>
          <FeedCalculator data={data.sections.feedCalc.right.data}/>
        </Section>
        <Section bgColor="darkGray">
          <TextBox data={data.sections.feedCalcExplained.left.data} altColor/>
          <TableContainer data-aos="fade-up"
            className="disableAosMobile"
        >
          <Table>
            <TableHeading>
              <TableRow>
                {data.sections.feedCalcExplained.right.data.heading.map(
                  (headingCell) => (
                    <TableHeaderCell key={uuid()}>
                      {headingCell}
                    </TableHeaderCell>
                  )
                )}
              </TableRow>
            </TableHeading>
            <TableBody>
              {data.sections.feedCalcExplained.right.data.rows.map((row) => (
                <TableRow key={uuid()}>
                  {row.map((cell) => (
                    <TableCell key={uuid()}>{cell}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Section></SectionsWrappper>
    </>
  );
};

export default CalculatorLayout;
