import React, {useEffect, useState} from "react";
import {Form, FormGroup, FormInput, Label} from "./styles";

const FeedCalculator = ({data}) => {
  const [n, setN] = useState(data.n.value);
  const [z, setZ] = useState(data.z.value);
  const [fz, setFz] = useState(data.fz.value);
  const [f, setF] = useState(data.f.value);

  useEffect(() => {
    setF(Math.round(parseFloat(fz) * parseInt(z) * parseInt(n)));
  }, [n, z, fz]);

  return (
    <Form>
      <FormGroup data-aos="fade-up"
        className="disableAosMobile"
      >
        <Label htmlFor={data.n.id}>{data.n.label}</Label>
        <FormInput
          type="number"
          id={data.n.id}
          value={n || 0}
          onChange={(e) => setN(e.target.value)}
        />
      </FormGroup>
      <FormGroup data-aos="fade-up"
        className="disableAosMobile"
      >
        <Label htmlFor={data.z.id}>{data.z.label}</Label>
        <FormInput
          type="number"
          id={data.z.id}
          value={z || 0}
          onChange={(e) => setZ(e.target.value)}
        />
      </FormGroup>
      <FormGroup data-aos="fade-up"
        className="disableAosMobile"
      >
        <Label htmlFor={data.fz.id}>{data.fz.label}</Label>
        <FormInput
          type="number"
          id={data.fz.id}
          value={fz || 0}
          onChange={(e) => setFz(e.target.value)}
        />
      </FormGroup>

      <FormGroup data-aos="fade-up"
        className="disableAosMobile"
      >
        <Label htmlFor={data.f.id}>{data.f.label}</Label>

        <FormInput type="number" disabled id={data.f.id} value={f || 0}/>
      </FormGroup>
    </Form>
  );
};

export default FeedCalculator;
