import React, {useEffect, useState} from "react";
import {BgWrapper, Container, ParallaxContainer, ParallaxText, StyledGlobalWrapper,} from "./styles";

const isBrowser = typeof window !== `undefined`;
let window_width = 0;
if (isBrowser) {
  window_width = window.innerWidth;
}

const Section = ({children, bgColor, fontWhite, parallaxText}) => {
  const [parallaxVisible, setParallaxVisible] = useState(true);

  useEffect(() => {
    if (window_width < 1100) {
      setParallaxVisible(false);
    }
  }, [])
  return (
    <BgWrapper bgColor={bgColor} fontWhite={fontWhite} as="section">
      <StyledGlobalWrapper>
        <Container>{children}</Container>
        {parallaxText && (
          <ParallaxContainer disabled={!parallaxVisible} className="parallax-text" x={[-20, 20]}>
            <ParallaxText>{parallaxText}</ParallaxText>
          </ParallaxContainer>
        )}
      </StyledGlobalWrapper>
    </BgWrapper>
  );
};

export default Section;
