import styled from "styled-components";
import {GlobalWrapper, SectionWrapper} from "shared/Wrappers/wrappers";
import {Parallax} from "react-scroll-parallax";

export const StyledGlobalWrapper = styled(GlobalWrapper)`
    position: relative;
    margin-top: -1px;
`;

export const ParallaxContainer = styled(Parallax)`
    position: absolute;
    right: 0;
    bottom: -5%;
    left: -17%;
    overflow: hidden;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        left: 0;
        position: absolute;
        bottom: 16px;

    }
`;

export const ParallaxText = styled.span`
    font-size: 500px;
    font-weight: 900;
    -webkit-text-stroke: 2px #eaeaea;
    color: transparent;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 384px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_small}) {
        font-size: 332px;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        font-size: 130px;
        display: block;
        text-align: center;
    }


    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) and (orientation: portrait) {
        font-size: 74px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        font-size: 100px;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) and (orientation: portrait) {
        font-size: 54px;
    }
`;

export const Container = styled(SectionWrapper)`
    display: flex;
    padding: 200px 0;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        padding: 154px 0;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_small}) {
        padding: 134px 0;
    }

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        padding: 114px 0;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        padding: 90px 24px;
        flex-direction: column;
        justify-content: center;
    }
`;

export const BgWrapper = styled.section`
    width: 100%;
    z-index: 3;
    position: relative;
    background-color: ${({theme, bgColor}) =>
            bgColor === "lightGray"
            ? theme.colors.gray_light_bg
            : bgColor === "darkGray"
              ? theme.colors.dark_gray
              : theme.colors.white};

    &:first-of-type {
        ${Container} {
            padding-bottom: 450px;

            @media screen and (max-width: ${({theme}) =>
                    theme.breakpoints.laptop}) {
                padding-bottom: 346px;
            }

            @media screen and (max-width: ${({theme}) =>
                    theme.breakpoints.laptop_small}) {
                padding-bottom: 300px;
            }

            @media screen and (max-width: ${({theme}) =>
                    theme.breakpoints.tablet_big}) {
                padding-bottom: 258px;
                margin-top: 0;
            }

            @media screen and (max-width: ${({theme}) =>
                    theme.breakpoints.tablet}) {
                padding-bottom: 204px;
            }

            @media screen and (max-width: ${({theme}) =>
                    theme.breakpoints.tablet}) and (orientation: portrait) {
                padding-bottom: 124px;
            }
        }
    }
`;
