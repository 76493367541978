import React, {useEffect, useState} from "react";
import {Form, FormGroup, FormInput, Label} from "./styles";

const RpmCalculator = ({data}) => {
  const [d, setD] = useState(data.d.value);
  const [vc, setVc] = useState(data.vc.value);
  const [n, setN] = useState(data.n.value);

  useEffect(() => {
    setN(Math.round((parseInt(vc) * 1000) / (3.14 * parseInt(d))));
  }, [d, vc]);

  return (
    <Form>
      <FormGroup data-aos="fade-up"     className="disableAosMobile">
        <Label htmlFor={data.d.id}>{data.d.label}</Label>
        <FormInput
          type="number"
          id={data.d.id}
          value={d || 0}
          onChange={(e) => setD(e.target.value)}
        />
      </FormGroup>
      <FormGroup data-aos="fade-up"     className="disableAosMobile">
        <Label htmlFor={data.vc.id}>{data.vc.label}</Label>
        <FormInput
          type="number"
          id={data.vc.id}
          value={vc || 0}
          onChange={(e) => setVc(e.target.value)}
        />
      </FormGroup>
      <FormGroup data-aos="fade-up"     className="disableAosMobile">
        <Label htmlFor={data.n.id}>{data.n.label}</Label>
        <FormInput type="number" disabled id={data.n.id} value={n || 0}/>
      </FormGroup>
    </Form>
  );
};

export default RpmCalculator;
