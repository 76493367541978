import styled, { css } from "styled-components";

export const TableContainer = styled.div`
  width: 45%;
  display: flex;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    margin-top: 44px;
    justify-content: center;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
`;

const cellStyles = css`
  padding: 24px;
  color: ${({ theme }) => theme.colors.gray};
  border: 1px solid ${({ theme }) => theme.colors.gray};
  font-size: 18px;
  text-align: center;
  white-space: nowrap;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 16px;
    padding: 18px;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 16px;
    white-space: pre-wrap;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    font-size: 14px;
    padding: 14px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    font-size: 12px;
    padding: 12px;
  }
`;

export const TableHeaderCell = styled.th`
  ${cellStyles}
`;

export const TableBody = styled.tbody``;

export const TableCell = styled.td`
  ${cellStyles}
  @media screen and (max-width: ${({ theme }) =>
    theme.breakpoints.tablet_big}) {
    white-space: nowrap;
  }
`;

export const TableHeading = styled.thead`
  ${TableHeaderCell} {
    border-top: none;
    border-left: none;
    font-weight: ${({ theme }) => theme.weights.normal};

    &:last-child {
      border-right: none;
    }
  }
`;

export const TableRow = styled.tr`
  ${TableCell} {
    &:not(:first-child) {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  &:first-child {
    ${TableCell} {
      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
    }
  }

  &:last-child {
    ${TableCell} {
      border-bottom: none;
      border-left: none;
      &:last-child {
        border-right: none;
      }
    }
  }
`;
