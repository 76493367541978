import React from "react";
import CalculatorLayout from "sections/CalculatorLayout/CalculatorLayout";
import useCalculatorData from "lang/en/calculator.js";
const Calculator = () => {
  const data = useCalculatorData();

  return <CalculatorLayout data={data} />;
};

export default Calculator;
