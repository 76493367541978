import styled from "styled-components";
import { TitleXL, Paragraph } from "components/Typography/Typography";

export const Container = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    text-align: center;
  }
`;

export const Title = styled(TitleXL)`
  color: ${({ altColor, theme }) => (altColor ? theme.colors.gray : "")};
  line-height: 1.5;
`;

export const SubTitle = styled(Paragraph)`
  margin-top: 24px;
  color: ${({ altColor, theme }) => (altColor ? theme.colors.gray : "")};
  line-height: 1.5;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 0px;
  }
`;

export const Text = styled(Paragraph)`
  color: ${({ altColor, theme }) => (altColor ? theme.colors.gray : "")};
  line-height: 1.5;
`;

export const MathContainer = styled.div`
  margin: 64px 0;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: ${({ theme }) => theme.weights.semibold};
  text-align: center;
  color: ${({ altColor, theme }) => (altColor ? theme.colors.white : "")};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 22px;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 20px;
    margin: 44px 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: center;
  }
`;

export const EquasionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Result = styled.span`
  margin-right: 8px;
`;

export const EquasionItem = styled.span`
  &:first-child {
    padding-bottom: 1px;
  }
  &:nth-child(2) {
    padding-top: 1px;
    border-top: 2px solid black;
  }
`;
